import React, {useState} from "react";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CustomizedRating from "../../components/CustomizedRating";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {API} from "aws-amplify";
const surveyQuestions = require('../../Datasource/SurveyQuestions.json');

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    ratingControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
        padding: '0 15px'
    },
    commentControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    submitButton: {
        marginBottom: '100px'
    },
    segmentedQuestion: {
        // margin: '25px 0'
    },
}));

export default function SurveyQuestions({setIsSurveySumitted, setIsLoading, surveyUuid, username, professor}) {
    const classes = useStyles();
    const [datasource, setDatasource] = useState(surveyQuestions);

    const submitSurveyEntryApi = () => {
        let body = {surveyUuid, username};
        datasource.forEach(o => {
            body[o.key] = o.value
        });
        return API.post("misServiciosEscolares", "/survey", {
            body:body
        });
    }

    const setSurveyAnswer = (index, value) => {
        console.log(index, value);
        if (value) {
            console.log(index, value);
            setDatasource([
                ...datasource.slice(0, index),
                {...datasource[index], value: value},
                ...datasource.slice(index + 1)
            ]);
        };
    }

    async function submitSurvey() {
        setIsLoading(true);
        try {
            await submitSurveyEntryApi();
            setIsSurveySumitted(true);
        } catch (e) {
            alert("No se pudieron guardar tus respuestas. Por favor intenta de nuevo");
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Typography variant="h4">
                Encuesta de Calidad de Servicio
            </Typography>
            <Typography>
                Evaluación para profesor {professor}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    {datasource.map((o, index) => (
                        <div className={classes.segmentedQuestion} key={index}>
                            <CustomizedRating
                                display={o.display}
                                defaultValue={o.value}
                                surveQuestionIndex={index}
                                setSurveyAnswer={setSurveyAnswer}
                            />
                        </div>
                    ))}
                </Grid>
                <Box width={1}>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        className={classes.submitButton}
                        onClick={submitSurvey}
                    >
                        Mandar Encuesta
                    </Button>
                </Box>
            </Grid>
        </>
    );
};