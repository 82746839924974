import React, {useState} from "react";
import {Backdrop, CircularProgress, Container} from "@material-ui/core";
import SurveyQuestions from "./SurveyQuestions";
import SurveySubmittedSuccessfully from "./SurveySubmittedSuccessfully";
import {parseQueryParam} from "../../components/CommonUtilities/UtilityFunctions";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function Survey(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [isSurveySubmitted, setIsSurveySubmitted] = useState(false);
    const surveyUuid = parseQueryParam("display", props.location.search);
    const username = props.match.params.userId;
    const professor = parseQueryParam("profesor", props.location.search);
    console.log(professor);
    console.log(parseQueryParam("profesor", props.location.search));

    return (
        <Container>

            {isSurveySubmitted
                ? <SurveySubmittedSuccessfully setIsSurveySubmitted={setIsSurveySubmitted}/>
                : <SurveyQuestions
                    setIsSurveySumitted={setIsSurveySubmitted}
                    surveyUuid={surveyUuid}
                    setIsLoading={setIsLoading}
                    username={username}
                    professor={professor}
                />
            }
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </Container>
    );
}
