import "./App.css";
// import config from "./config";
import Routes from "./Routes";
// import {Auth} from "aws-amplify";
import {withRouter} from "react-router-dom";
// import Tooltip from '@material-ui/core/Tooltip';
import React from "react";
// import {LinkContainer} from "react-router-bootstrap";
// import {Nav, Navbar, Form, NavDropdown} from "react-bootstrap";

function App(props) {

    return (
        <div className="App container">
            <Routes appProps={{}}/>
        </div>
    );
}

export default withRouter(App);