/**
 * Gets the value for a query param, i.e. /students/123?details=true will get true if the queryParam is details
 * @param queryParam
 * @param propsLocationSearch this is props.location.search
 * @returns {string}
 */
export const parseQueryParam = (queryParam, propsLocationSearch) => {
    if (!(queryParam && propsLocationSearch)) {
        return "";
    }
    const query = new URLSearchParams(propsLocationSearch);
    return query.get(queryParam);
};