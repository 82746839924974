import React from "react";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {green} from "@material-ui/core/colors";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    successIcon: {
        color: green[500],
        fontSize: "100px"
    },
    returnButton: {
        margin: "50px 0"
    }
}));


export default function SurveySubmittedSuccessfully({setIsSurveySubmitted}) {
    const classes = useStyles();

    function returnToSurvey() {
        setIsSurveySubmitted(false)
    }

    return (
        <div style={{textAlign: "center"}}>
            <Typography variant="h2">
                Muchas gracias!
            </Typography>
            <CheckCircleIcon className={classes.successIcon}/>
            <Typography variant="h4">
                Tu participación nos ayuda a mejorar.
            </Typography>
            <Button
                className={classes.returnButton}
                variant="contained"
                color="secondary"
                fullWidth
                onClick={returnToSurvey}
            >
                Regresar a la encuesta
            </Button>
        </div>
    );

};