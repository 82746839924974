import React from "react";
import {Route, Switch} from "react-router-dom";
// import Home from "./containers/Containers/Home";
import AppliedRoute from "./components/AppliedRoute";
import NotFound from "./containers/Containers/NotFound";
import Survey from "./containers/Containers/Survey";

export default function Routes({appProps}) {
    return (
        <Switch>
            <AppliedRoute path="/" exact component={Survey} appProps={appProps}/>
            <AppliedRoute path="/survey" exact component={Survey} appProps={appProps} />
            <AppliedRoute path="/surveys/:userId" exact component={Survey} appProps={appProps} />
            <Route component={NotFound}/>
        </Switch>
    );
};
