import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1, 1, 0, 0),
    },
}));

export default function CustomizedRating({display, surveQuestionIndex, defaultValue, setSurveyAnswer}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(defaultValue || '1');

    const handleRadioChange = (event) => {
        setValue(event.target.value);
        setSurveyAnswer(surveQuestionIndex, event.target.value);
    };

    return (
        <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">{surveQuestionIndex + 1}. {display}</FormLabel>
            <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange}>
                <FormControlLabel value="0" control={<Radio/>} label="Siempre"/>
                <FormControlLabel value="1" control={<Radio/>} label="Casi siempre"/>
                <FormControlLabel value="2" control={<Radio/>} label="Algunas veces"/>
                <FormControlLabel value="3" control={<Radio/>} label="Nunca"/>
            </RadioGroup>
        </FormControl>
    );
}
